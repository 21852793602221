import { ThemeProvider } from '@mui/material';
import { muiTheme } from './utils/theme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './components/home-page/HomePage';
import './App.scss';

export function App(): JSX.Element {
  return (
    <ThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
